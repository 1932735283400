import React from "react"
import { Link, navigate } from 'gatsby'
import Text from '../Text'
import { formatTime, isInFuture } from '../../utils/dates'
import "./styles.scss"
const NUMEVENTS = 2

const HomePageEvents = ({ events, imageData }) => {
  const futureEvents = events.filter(e => isInFuture(e.date, '23:59:59'))
  const livestreamClicked = (event) => {
    const minsBeforeEvent = event.publish_link_before || 0
    if (event.live_stream_link) {
      if (isInFuture(event.date, event.start_time, minsBeforeEvent)) {
        navigate(`/livestream/?date=${event.format_date}&time=${formatTime(event.start_time, minsBeforeEvent)}`)
      } else {
        if (typeof window !== 'undefined') window.open(event.live_stream_link, '_blank')
      }
    }
  }
  return (
    <div className="homePageEvents container">
      <div className="row">
        <div className="col-md-6">
          <Text.Header className="my-0">Upcoming Events</Text.Header>
          {
            futureEvents.length > 0 ? futureEvents.map((event, index) => {
              if (index < NUMEVENTS) {
                return (
                  <div className="text-left" key={index} data-testid={"event-" + index}>
                    <Text.Header size="subheader" className="mt-4">{event.name}</Text.Header>
                    <div className="pl-3">
                      <Text.Normal className="body mt-2"><strong>Date:</strong>&nbsp;{event.format_date}</Text.Normal>
                      <Text.Normal className="body mb-2"><strong>Time:</strong>&nbsp;{formatTime(event.start_time)} CST</Text.Normal>
                      <Text.Normal className="body mt-2 mb-0">{event.description}</Text.Normal>
                      {event.live_stream_link ? <button className="link" data-testid={"view-livestream-link-" + index} onClick={() => livestreamClicked(event)}>Watch event</button> : null}
                    </div>
                  </div>
                )
              } else return null
            }) : (<div className="text-left mt-3">
              <Text.Normal data-testid="no-upcoming-events" className="body my-0">
                We currently do not have any upcoming events. <br />
                Please check back later!
              </Text.Normal>
            </div>)}
          {futureEvents.length > 0 ? (<div className="mt-4 pt-3">
            <Link className="link" data-testid="see-more-link" to="/events/">
              <Text.Header size="subheader">
                See More
              </Text.Header>
            </Link>
          </div>) : null}
        </div>
        <div className="col-md-6 text-left">
          <img className="mt-2 mx-auto rounded" data-testid="home-page-events-image" alt={imageData.alt_text} src={imageData.image} />
        </div>
      </div>
    </div>
  )
}

export default HomePageEvents