import React from "react"
import Text from '../Text'
import "./styles.scss"

const MissionStatement = () => (
  <div className="mission text-center">
    <div className="mission-text text-left d-inline-block">
      <Text.Header className="statement" size="large" weight="lighter">
        We are students and teachers.<br />
      Soloists and collaborators.<br />
      Performers and storytellers.<br />
      </Text.Header>
      <Text.Header className="statement" size="large" weight="bold">
        We are Emerging Artists Chicago.
      </Text.Header>
    </div>
  </div>
)

export default MissionStatement