import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import MissionStatement from "../components/MissionStatement"
import HomePageEvents from "../components/HomePageEvents"
import HomeIconsContainer from "../components/HomeIconsContainer"

const IndexPage = ({ data }) => {

  return (
    <Layout noBreadcrumb thick banner={data.banners}>
      <Seo title="Home" />
      <HomeIconsContainer />
      <MissionStatement />
      <HomePageEvents imageData={data.upcomingEventsImage} events={data.allEvents.nodes} />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
    query {
      banners(page_url: {eq: "/home"}) {
        link_label
        link_url
        text
        image
        copyright_text
      }
      allEvents(sort: {fields: [date, start_time], order: [ASC, ASC]}) {
        nodes {
            id
            format_date: date(formatString: "MMMM D, YYYY")
            date
            description
            start_time
            live_stream_link
            publish_link_before
            name
        }
      }
      upcomingEventsImage {
        image
        alt_text
      } 
    }
  `
