import React from "react"
import CardLink from "../CardLink"
import "./styles.scss"

const HomeIconsContainer = () => {
  return (
    <div className="cardsContainer row no-gutters m-auto justify-content-center">
      <CardLink
        to="/events/"
        icon="https://res.cloudinary.com/dthskrjhy/image/upload/v1602739556/Emerging%20Artists%20Chicago/home/Calendar.png"
        label="Calendar"
        description="Explore upcoming competitions, performances, and other virtual events."
        className="col-md-4 first"
      />
      <CardLink
        to="/news/"
        icon="https://res.cloudinary.com/dthskrjhy/image/upload/v1602739558/Emerging%20Artists%20Chicago/home/News.png"
        label="News"
        description="Check here for community updates and other important announcements."
        className="col-md-4"
      />
      <CardLink
        to="/videos/"
        icon="https://res.cloudinary.com/dthskrjhy/image/upload/v1602739559/Emerging%20Artists%20Chicago/home/Videos.png"
        label="Videos"
        description="Browse past performances, student competitions, live streams, and more."
        className="col-md-4 last"
      />
    </div>
  )
}

export default HomeIconsContainer