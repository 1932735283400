import React from 'react'
import { Link } from 'gatsby'
import Text from '../Text'
import './styles.scss'


export const CardLink = ({ to, icon, label, description, className }) => {
  return (
    <div className={`${className || ''} text-center cardLink`}>
      <Link className="icon-box d-block m-auto" data-testid="event-icon-link" to={to}>
        <img className="mb-4" alt="" src={icon} />
        <Text.Header className="mb-4">{label}</Text.Header>
        <Text.Normal className="mx-auto icon-desc">{description}</Text.Normal>
      </Link>
    </div>
  )
}

export default CardLink